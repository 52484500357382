import React from "react"
import { Link } from "gatsby"
import { FaCarSide } from "react-icons/fa"
import { GiCarKey } from "react-icons/gi"
import { BsCardChecklist } from "react-icons/bs"

import Layout from "../layout"
import SEO from "../components/seo"
import Jumbo from "../components/jumbo"
import Slagord from "../components/slagord"
import ButtonLink from "../components/buttonlink"
import Reviews from "../components/reviews"
import { useStaticQuery } from "gatsby"

import Iframe from "react-iframe"

const query = graphql`
  query {
    prismicNyheterstartsida {
      dataRaw
    }
  }
`

const IndexPage = () => {
  const { prismicNyheterstartsida } = useStaticQuery(query)

  const rubrik = prismicNyheterstartsida?.dataRaw?.rubrik[0]?.text
  const announcementText = prismicNyheterstartsida?.dataRaw?.brodtext[0]?.text

  return (
    <Layout>
      <SEO title="Amins Trafikskola" />
      <Jumbo />
      {/*<Kampanj firstStart={kampanjStart} priceNum={kampanjPris} />*/}
      <Slagord />
      <div
        style={{
          padding: "2em",
          backgroundColor: "white",
          margin: "0",
        }}
        className="flex"
      >
        <aside style={{ paddingRight: "2em" }} className="">
          <h2>Att ta körkort skall inte kosta skjortan!</h2>
          <div
            className="flex"
            style={{ color: "rgb(196, 44, 0)", padding: "8px 0" }}
          >
            <FaCarSide size={32} style={{ marginRight: 16 }} />
            <BsCardChecklist size={32} style={{ marginRight: 16 }} />
            <GiCarKey size={32} style={{ marginRight: 16 }} />
          </div>
        </aside>
        <main className="column">
          <p>
            Vår målsättning är att du som kör hos oss ska få maximalt värde för
            dinga pengar. Detta uppnår vi genom en effektiv och anpassad
            körutbildning
          </p>
          <Link to="/priser/">
            <ButtonLink>Priser och paketerbjudanden</ButtonLink>
          </Link>
          <br />
        </main>
      </div>

      <hr />

      {/* Custom announcement */}
      <div
        style={{
          padding: "2em",
          backgroundColor: "white",
          margin: "0",
        }}
      >
        <br />
        <h2>{rubrik}</h2>
        <p>{announcementText}</p>
      </div>

      <Reviews />
      <div className="flex flex-col sm:flex-row w-full">
        <div className="flex-1 bg-white p-2">
          {/* <a href="https://www.google.com/maps/place/Albins/@57.71209,11.8916641,15z/data=!4m5!3m4!1s0x0:0x2f6ff32d760fd347!8m2!3d57.71209!4d11.8916641">
          <img src={require('./../images/google.png')} alt='google reviews' />
          <img src={require('./../images/reviews.png')} alt='google reviews' />
        </a> */}
          <iframe
            title="google map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2131.3306899609793!2d11.888154866182647!3d57.710840346755155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464f8ca2e8a124bf%3A0x2f6ff32d760fd347!2sAlbins!5e0!3m2!1sen!2sse!4v1574622262734!5m2!1sen!2sse"
            width="400"
            height="300"
            frameBorder="0"
            style={{ border: "0", height: "450px", width: "100%" }}
            allowFullScreen=""
          />
        </div>
        <div className="flex bg-white fb-feed">
          <Iframe
            url="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Falbinstrafik%2F&tabs=timeline&width=500&height=800&small_header=true&hide_cover=true&show_facepile=false&appId"
            width="500"
            height="500"
          />
        </div>
      </div>
      {/* <div id="fb-root"></div>
    <div class="fb-page" data-href="https://www.facebook.com/albinstrafik/" data-tabs="timeline" data-width="" data-height="" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/albinstrafik/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/albinstrafik/">Albins Trafikskola</a></blockquote></div> */}
    </Layout>
  )
}

export default IndexPage
